import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoLight from "../../../assets/logo.svg";
import Logo1 from "../../../assets/logo_white.svg";
import PoweredLight from "../../../assets/powered.svg";
import Powered1 from "../../../assets/powered_white.svg";
import MobilePowered1 from "../../../assets/mobile_powered_white.svg";
import RED_HEART_ICON from "../../../assets/red_heart.svg";
import RED_HEART_DARK_ICON from "../../../assets/red_heart_dark.svg";
import ANIMATED_HEART_LIGHT from "../../../assets/animated_heart_light.gif";
import ANIMATED_HEART_DARK from "../../../assets/animated_heart_dark.gif";
import Powered_mobile_light from "../../../assets/mobile_powered_light.svg";
import styles from "./styles.module.scss";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function TopBar() {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [isHome, setIsHome] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const trackScroll = () => {
    setIsSticky(window.scrollY > 0);
  };
  useEffect(() => {
    setIsHome(location.pathname === "/");
    setIsSticky(window.scrollY > 0);
    document.addEventListener("scroll", trackScroll);
  }, [location]);

  return (
    <header
      className={styles.header}
      data-is-stick={isSticky}
      data-is-home={isHome}
    >
      <div className={styles.container}>
        <Link to="/">
          <img
            src={(isSticky && width > 1200) || !isHome ? LogoLight : Logo1}
            alt="logo"
            width="136px"
            height="58px"
            className={styles.logo}
          />
        </Link>
        <Link to="https://www.mailmodo.com/" target="_blank">
          {width < 800 ? (
            <img
              src={isHome ? MobilePowered1 : Powered_mobile_light}
              alt="logo"
              width="86px"
              height="48px"
              className={styles.powered}
            />
          ) : (
            <div
              className={styles.powered_container}
              data-is-dark={(isSticky && width > 1200) || !isHome}
              data-is-home-is-not-sticky={!isSticky&&isHome}
            >
              <div className={styles.heart}>
                <img
                  src={
                    (isSticky && width > 1200) || !isHome
                      ? RED_HEART_ICON
                      : RED_HEART_DARK_ICON
                  }
                  width="20px"
                  height="18px"
                  alt="Heart"
                  className={`${styles.red_heart} ${styles.static}`}
                />
                <img
                  src={
                    (isSticky && width > 1200) || !isHome
                      ? ANIMATED_HEART_LIGHT
                      : ANIMATED_HEART_DARK
                  }
                  width="20px"
                  height="18px"
                  alt="Heart"
                  className={`${styles.red_heart} ${styles.animation}`}
                />
              </div>

              <p className={styles.text}>Made with love by</p>
              <img
                src={
                  (isSticky && width > 1200) || !isHome
                    ? PoweredLight
                    : Powered1
                }
                alt="logo"
                width="101px"
                height="18.42px"
                className={styles.powered}
              />
            </div>
          )}
        </Link>
      </div>
    </header>
  );
}

export default TopBar;
