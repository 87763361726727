import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../global/ValidateEmail";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { getImageUrl } from "../../../utils/functions";
import styles from "./features.module.scss";
const Features = () => {
  const [email, setEmail] = useState({ id: "", error: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { width } = useWindowDimensions();

  const handleUnlockFormSubmission = (e) => {
    e.preventDefault();
    console.log(email);
    // TODO : Need to add API call
  };

  const handleApiCall = () => {
    if (!validateEmail(email.id)) {
      setEmail({ ...email, error: true });
      return;
    }
    setIsLoading(true);
    const API =
      "https://eof7oofr4joja4p.m.pipedream.net?url=https://api.mailmodo.com/hooks/start/f09549e3-f511-4041-832e-5b05594e80a4";
    axios
      .post(API, {
        email: email.id,
        data: { lead_magnet: "Dark Mode Checker" }, // Adding lead_magnet in data will send the data to  Hubspot as well
      })
      .then(() => {
        setMessage(
          width > 800
            ? "Please check your inbox, The Dark Mode Email Playbook has been delivered."
            : "Please check your inbox"
        );
      })
      .catch((err) => {
        console.error(err.response);
        setMessage("Something went wrong. Please retry");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleEmailInput = ({ target }) => {
    setMessage("");
    if (validateEmail(email.id)) {
      setEmail({ error: false, id: target.value });
    } else {
      setEmail({ ...email, id: target.value });
    }
  };

  return (
    <>
      <div className={styles.blue_underline_cards}>
        <h4 className={styles.head1}>
          Why check your emails for dark mode compatibility?
        </h4>
        <p className={styles.para2}>
          Dark mode adoption is on a high which means you need to ensure that
          your emails are dark mode ready
        </p>
        <div className={styles.container_main}>
          <div className={styles.main_div_cards1}>
            <div
              className={styles.main_sub_div_cards}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div>
                <div className={styles.number_card}>
                  <h3 className={styles.head2}>92%</h3>
                  <div className={styles.logo_up}>
                    <img
                      src={
                        "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/logo_up_a79c05d79d.png"
                      }
                      alt={"up logo"}
                      width={32}
                      height={32}
                    />
                  </div>
                </div>
                <p className={styles.text_2}>
                  recipients view
                  <br /> emails in dark mode{" "}
                </p>
              </div>
              <div className={styles.card_footer}>
                <span className={styles.card_footer_text}>
                  Source: Android Authority
                </span>
              </div>
            </div>
            <div
              className={styles.main_sub_div_cards}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className={styles.number_card}>
                <h3 className={styles.head2}>55-70%</h3>
                <div className={styles.logo_up}>
                  {" "}
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/logo_up_a79c05d79d.png"
                    }
                    alt={"up logo"}
                    width={32}
                    height={32}
                  />
                </div>
              </div>
              <p className={styles.text_2}>
                dark mode adoption rate among Apple iOS users
              </p>

              <div className={styles.card_footer}>
                <span className={styles.card_footer_text}>
                  Source: Night Eye App
                </span>
              </div>
            </div>
            <div
              className={styles.main_sub_div_cards}
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className={styles.number_card}>
                <h3 className={styles.head2}>82%</h3>
                <div className={styles.logo_up}>
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/logo_up_a79c05d79d.png"
                    }
                    alt={"up logo"}
                    width={32}
                    height={32}
                  />
                </div>
              </div>
              <p className={styles.text_2}>
                Android users
                <br /> prefer dark mode{" "}
              </p>
              <div className={styles.card_footer}>
                <span className={styles.card_footer_text}>
                  Source: Android Authority
                </span>
              </div>
            </div>
            <div
              className={styles.main_sub_div_cards}
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className={styles.number_card}>
                <h3 className={styles.head2}>48%</h3>
                <div className={styles.logo_up}>
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/logo_up_a79c05d79d.png"
                    }
                    alt={"up logo"}
                    width={32}
                    height={32}
                  />
                </div>
              </div>
              <p className={styles.text_2}>
                B2C brands optimize
                <br /> emails for dark mode
              </p>
              <div className={styles.card_footer}>
                <span className={styles.card_footer_text}>
                  Source: Marketing Charts
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.image_cards_container}>
        <p className={styles.text3}>
          Different email clients render emails differently in dark mode
        </p>
        <div data-aos="fade-up" data-aos-delay="100">
          <div className={styles.cardImage}>
            <img
              src={
                "https://res.cloudinary.com/mailmodo/image/upload/v1674644117/strapi/Group_1110165325_e49e6c800d.png"
              }
              width={1240}
              height={636}
              alt={"Inversion"}
            />
          </div>
        </div>
        <div>
          <div className={styles.cardImageMobile}>
            <div
              className={styles.cardImageMobileStyles}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src={
                  "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/Dark_Mode_Card_Mobile_View1_47d3c4cbb3.png"
                }
                alt="Inversion 1"
                width={324}
                height={415}
                object-fit="contain"
              />
            </div>
            <div
              className={styles.cardImageMobileStyles}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src={
                  "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/Dark_Mode_Card_Mobile_View2_c0f7aedc05.png"
                }
                alt="Inversion 2"
                width={324}
                height={334}
                object-fit="contain"
              />
            </div>
            <div
              className={styles.cardImageMobileStyles}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                src={
                  "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/Dark_Mode_Card_Mobile_View3_ddde03269c.png"
                }
                alt="Inversion 3"
                width={324}
                height={498}
                object-fit="contain"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.dark_cta_container}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className={styles.container}>
          <div className={styles.col_left}>
            <h2 className={styles.heading}>
              The ultimate dark
              <br /> mode email library
            </h2>
            <p className={styles.description}>
              Browse our ready-to-use dark mode friendly
              <br /> email templates for your campaigns
            </p>
            <Link to="https://www.mailmodo.com/email-templates/categories/dark-mode-email/">
              <button className={styles.btn}>Explore now</button>
            </Link>
          </div>
          <div className={styles.col_right}>
            <img
              className={styles.desktop_image}
              src={getImageUrl(
                "https://res.cloudinary.com/mailmodo/image/upload/v1678971008/strapi/emaillibrary_4875aaafbd.png"
              )}
              loading="lazy"
              width="614px"
              height="413px"
              alt="template"
            />
            <img
              className={styles.mobile_image}
              src={getImageUrl(
                "https://res.cloudinary.com/mailmodo/image/upload/v1678975085/strapi/Group_1110165357_3_93d903c68e.png"
              )}
              loading="lazy"
              width="279px"
              height="183px"
              alt="template"
            />
          </div>
          <img
            src={getImageUrl(
              "https://res.cloudinary.com/mailmodo/image/upload/v1678972250/strapi/dots_right_72b1bfa670.png"
            )}
            alt="dots"
            className={styles.dots_right}
            loading="lazy"
            width="1228px"
            height="456px"
          />
          <img
            src={getImageUrl(
              "https://res.cloudinary.com/mailmodo/image/upload/v1678972250/strapi/dots_left_5939f356bc.png"
            )}
            alt="dots"
            className={styles.dots_left}
            loading="lazy"
            width="478px"
            height="456px"
          />
        </div>
      </div>
      <div className={styles.tweets_section}>
        <h2 className={styles.heading}>
          You’re not alone to face dark mode issues
        </h2>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link
              to="https://twitter.com/antony/status/1531737701413703680?s=20"
              target="_blank"
            >
              <img
                loading="lazy"
                src={getImageUrl(
                  "https://res.cloudinary.com/mailmodo/image/upload/v1678964402/strapi/Frame_1110165325_1d2ab7b6e5.png"
                )}
                className={styles.tweet}
                alt="tweet"
                width="297px"
                height="529px"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Link>
          </div>
          <div className={styles.col}>
            <Link
              to="https://twitter.com/erikras/status/1432686018306707460?s=20"
              target="_blank"
            >
              <img
                loading="lazy"
                src={getImageUrl(
                  "https://res.cloudinary.com/mailmodo/image/upload/v1678964407/strapi/Frame_1110165327_f80aa4e8a6.png"
                )}
                className={styles.tweet}
                alt="tweet"
                width="297px"
                height="529px"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Link>
          </div>
          <div className={styles.col} data-order-mobile="1">
            <Link
              to="https://twitter.com/emailfromnaomi/status/1603635141750173697?s=20"
              target="_blank"
            >
              <img
                loading="lazy"
                src={getImageUrl(
                  "https://res.cloudinary.com/mailmodo/image/upload/v1678964402/strapi/Frame_1110165326_859bfbdf12.png"
                )}
                className={styles.tweet}
                alt="tweet"
                width="297px"
                height="246px"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Link>
            <Link
              to="https://twitter.com/m_i_n_d_f_u_l_l/status/1599896314338082817?s=20"
              target="_blank"
              data-hide-mobile="true"
            >
              <img
                loading="lazy"
                src={getImageUrl(
                  "https://res.cloudinary.com/mailmodo/image/upload/v1679657304/strapi/Frame_1110165355_c20cda5155.png"
                )}
                className={styles.tweet}
                alt="tweet"
                width="297px"
                height="327px"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Link>
          </div>
          <div className={styles.col}>
            <Link
              to="https://twitter.com/livingkionna/status/1599275149412311041?s=20"
              target="_blank"
              data-hide-mobile="true"
            >
              <img
                loading="lazy"
                src={getImageUrl(
                  "https://res.cloudinary.com/mailmodo/image/upload/v1678964414/strapi/Frame_1110165328_08887e0029.png"
                )}
                className={styles.tweet}
                data-hide-mobile="true"
                alt="tweet"
                width="297px"
                height="527px"
                data-aos="fade-up"
                data-aos-delay="100"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* <div className={styles.arrow_animation_section}>
        <p className={styles.description}>
          What this means is that brands that beat dark mode issues
          <br /> are sure to create a larger impact than their competitors.
        </p>
        <div className={styles.animation}>
          <img
            alt="arrow"
            src={getImageUrl(
              "https://res.cloudinary.com/mailmodo/image/upload/v1678950568/strapi/arrowanimate_d5c8160305.gif",
              "webp",
              100
            )}
            width="52px"
            height="52px"
          />
        </div>
      </div> */}
      <div
        className={styles.unlock_form_section}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <h2 className={styles.main_heading}>
          Brands that beat dark mode issues stand out in the inbox
        </h2>
        <div className={styles.container}>
          {/* <h3 className={styles.heading} data-is-blur="true">
            Become an expert with our Dark Mode Email Playbook
          </h3> */}
          <img
            src={getImageUrl(
              "https://res.cloudinary.com/mailmodo/image/upload/v1678894482/strapi/unlockdakmodetemplatesbackground_dd005fb283.png"
            )}
            alt="background"
            width={"1308px"}
            height={"604px"}
            className={styles.background_image_desktop}
          />
          <img
            src={getImageUrl(
              "https://res.cloudinary.com/mailmodo/image/upload/v1678945498/strapi/unlockdakmodetemplatesbackground_Mobile2_ea305b0d5f.png"
            )}
            alt="background"
            width={"380px"}
            height={"647px"}
            className={styles.background_image_mobile}
          />
          <div className={styles.form_container}>
            <h3 className={styles.heading}>
              🔓 Unlock access to the Dark Mode Email Survival Kit
            </h3>
            <form onSubmit={handleUnlockFormSubmission}>
              <input
                type="email"
                placeholder="Enter your email address"
                required
                onChange={handleEmailInput}
              />
              <button onClick={handleApiCall}>
                {isLoading ? (
                  <CircularProgress color="inherit" size="25px" />
                ) : (
                  "Get it now"
                )}
              </button>
            </form>
            {message && <p className={styles.message}>{message}</p>}
          </div>
          <div className={styles.gradient}>
            <div className={styles.gradient_left} />
            <div className={styles.gradient_middle} />
            <div className={styles.gradient_right} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
