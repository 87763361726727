import styles from "./footer.module.scss";
import LogoLight from "../../../assets/logo.svg";

const FooterLight = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.container}>
      <div className={styles.logo}>
        <img
          src={LogoLight}
          alt="Logo"
          width={214}
          height={92}
          className={styles.mailmodo_logo}
        />
      </div>
      <p className={styles.description}>
        The Dark Mode Checker is a project produced and owned by Mailmodo to
        help email marketers create and send dark mode friendly emails by
        testing it with our Dark Mode Checker tool. The aim of this project is
        to help email and growth marketers do better email marketing.
      </p>
      <p className={styles.feedback_text}>
        Got something to tell us?
        <br /> Share your feedback with us at{" "}
        <a href="mailto:enquiries@mailmodo.com">enquiries@mailmodo.com</a>
      </p>
      <div className={styles.copyright_section}>
        <p className={styles.current_year}>
          ©Mailmodo Technologies Inc. {currentYear}
        </p>
        <div>
          <ul className={styles.links}>
            <li>
              <a href="https://www.mailmodo.com/email-templates/">
                Email Templates
              </a>
            </li>
            <li>
              <a href="https://www.mailmodo.com/guides/email-marketing/">
                Email Marketing Guide
              </a>
            </li>
            <li>
              <a href="https://www.ampmails.com/blog/amp-emails/">
                AMP Email Guide
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterLight;
