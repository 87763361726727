import React, { useEffect, useRef } from "react";
import Loading from "../../../assets/loading.json";
import bulb from "../../../assets/bulb.svg";
import Lottie from "lottie-react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import {
  LOCAL_STORAGE_ALIAS_KEY,
  LOCAL_STORAGE_TIME_KEY,
} from "../../../utils/constants";

function LoadingPage({
  setStep,
  setIsLoading,
  isProgressBarVisible,
  setIsProgressBarVisible,
  generateAlias,
  isShowWarning,
  waitForGmail,
  setIsShowWarning,
}) {
  const animationRef = useRef();
  const stopAnimation = () => {
    animationRef?.current?.stop();
  };
  useEffect(() => {
    stopAnimation();
    return () => {
      setIsProgressBarVisible(false);
      setIsShowWarning(false);
    };
    // eslint-disable-next-line
  }, []);

  const resetEmailAndSendNew = () => {
    localStorage.removeItem(LOCAL_STORAGE_ALIAS_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TIME_KEY);
    generateAlias();
    setStep(1);
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mobile_padding} />
        <div>
          <div className={styles.animation_container}>
            <div className={styles.animation}>
              <Lottie animationData={Loading} ref={animationRef} />
            </div>
            {isProgressBarVisible && (
              <div className={styles.bar}>
                <div className={styles.in}></div>
              </div>
            )}
          </div>
          <h2 className={styles.heading}>
            Almost there!
            <br />
            Please wait while we look for your email
          </h2>
          {isShowWarning && (
            <div className={styles.warning}>
              <img
                src="https://res.cloudinary.com/mailmodo/image/upload/v1680253091/strapi/envolope_empty_27b7d41c16.svg"
                alt="No Mail"
                width="41.5px"
                height="44px"
              />
              <div>
                <p className={styles.title}>No mail found yet. </p>
                <p className={styles.description}>
                  <span onClick={waitForGmail}>Try searching again</span> Or{" "}
                  <br />
                  <span onClick={resetEmailAndSendNew}>
                    send to a new email id
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.bottom_banner}>
          <p>
            <img src={bulb} width="24px" height="24px" alt="bulb" />
            <span>
              Did you know that you can create
              <br /> stunning, dark-mode compatible emails
              <br /> with Mailmodo?{" "}
              <Link to="https://manage.mailmodo.com/" target="_blank">
                Try it for free now {"->"}
              </Link>
            </span>
          </p>
        </div>
        <div className={styles.mobile_padding} style={{ height: "140px" }} />
      </div>
    </>
  );
}

export default LoadingPage;
