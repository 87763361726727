import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveEmailBody } from "../../api";
// import { Alert } from "../../global/Alert";
import LoadingPage from "./Loading/Loading";
import styles from "./styles.module.scss";
import envelope from "../../assets/envelope.svg";
import copyIcon from "../../assets/copy.svg";
import checkLight from "../../assets/check_light.svg";
import {
  LOCAL_STORAGE_ALIAS_KEY,
  LOCAL_STORAGE_TIME_KEY,
} from "../../utils/constants";

const RandomAlias = () =>
  Array(6)
    .fill(0)
    .map((x) => Math.random().toString(36).charAt(2))
    .join("");

const Email = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(true);
  const [emailId, setEmailId] = useState({
    alias: "",
    email: "",
  });
  const [step, setStep] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);
  const navigate = useNavigate();

  const navigateToResultPage = () => {
    navigate("/" + emailId.alias);
    localStorage.removeItem(LOCAL_STORAGE_ALIAS_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TIME_KEY);
  };

  const postEmailBody = async () => {
    let response = await saveEmailBody(emailId.email);
    if (response.status) {
      navigateToResultPage();
    } else {
      setTimeout(async () => {
        response = await saveEmailBody(emailId.email);
        if (response.status) {
          navigateToResultPage();
        } else {
          // Alert.showWarning("No mail has been found for copied email id");
          // setIsLoading(false);
          setIsShowWarning(true);
          setIsProgressBarVisible(false);
        }
      }, 35000);
    }
  };

  const waitForGmail = () => {
    setIsProgressBarVisible(true);
    setIsShowWarning(false);
    setTimeout(() => {
      postEmailBody();
    }, 10000);
    setIsLoading(true);
    // navigate("/" + emailId.alias);
  };

  const copiedFromInputField = () => {
    setIsCopied(true);
  };

  const copyInputText = () => {
    navigator.clipboard.writeText(emailId.email);
    setIsCopied(true);
    setTimeout(() => {
      setStep(2);
    }, 3000);
  };

  const handleStep = (val) => {
    if (val === 1) setIsCopied(false);
    setStep(val);
  };

  const generateAlias = () => {
    setIsProgressBarVisible(true);
    setIsCopied(false);
    const localStorageRandomAlias = localStorage.getItem(
      LOCAL_STORAGE_ALIAS_KEY
    );
    let random;
    if (localStorageRandomAlias) {
      random = localStorageRandomAlias;
    } else {
      random = RandomAlias();
      localStorage.setItem(LOCAL_STORAGE_ALIAS_KEY, random);
    }
    setEmailId({
      alias: random,
      email: "admin+" + random + "@checkdarkmode.com",
    });
  };

  useEffect(() => {
    const minutes = 60; // to clear the localStorage after 60 minutes
    // (if someone want to clear after 120 minutes simply change minutes=120)
    const now = new Date().getTime();
    const setupTime = localStorage.getItem(LOCAL_STORAGE_TIME_KEY);
    if (setupTime == null) {
      localStorage.setItem(LOCAL_STORAGE_TIME_KEY, now);
    } else {
      if (now - setupTime > minutes * 60 * 1000) {
        localStorage.removeItem(LOCAL_STORAGE_ALIAS_KEY);
        localStorage.setItem(LOCAL_STORAGE_TIME_KEY, now);
      }
    }
    generateAlias();
    document.body.style.backgroundColor = "#FBFBFF";
    return () => {
      document.body.style.backgroundColor = "transparent";
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingPage
          setIsLoading={setIsLoading}
          setStep={setStep}
          isProgressBarVisible={isProgressBarVisible}
          setIsProgressBarVisible={setIsProgressBarVisible}
          generateAlias={generateAlias}
          isShowWarning={isShowWarning}
          setIsShowWarning={setIsShowWarning}
          waitForGmail={waitForGmail}
        />
      ) : (
        <div className={styles.container}>
          <div>
            <p className={styles.steps}>Step 1/2</p>
            <div className={styles.progress_bar}>
              <div className={styles.bar} data-is-completed="true"></div>
              <div className={styles.bar} data-is-completed={step === 2}></div>
            </div>
          </div>
          {step === 1 && (
            <>
              <div className={styles.form_container}>
                <h2 className={styles.heading}>
                  Send your email template to the below address
                </h2>
                <div className={styles.form}>
                  <div className={styles.input_container}>
                    <input
                      type="email"
                      id="email-input"
                      className={styles.copy_email_input}
                      value={emailId.email}
                      onCopy={copiedFromInputField}
                      onChange={() => {}}
                    />
                    <img
                      src={envelope}
                      className={styles.before_icon}
                      height="30"
                      width="30"
                      alt="envelope"
                    />
                  </div>
                  <button
                    className={styles.copy_btn}
                    data-is-copied={isCopied}
                    onClick={() => copyInputText()}
                  >
                    {isCopied ? (
                      <img
                        src={checkLight}
                        className={styles.check_icon}
                        height="28"
                        width="28"
                        alt="done"
                      />
                    ) : (
                      <img
                        src={copyIcon}
                        className={styles.copy_icon}
                        height="24"
                        width="24"
                        alt="copy"
                      />
                    )}
                    {isCopied ? "Copied" : "Copy"}
                  </button>
                </div>
              </div>
              <div className={styles.next_btn_container}>
                <button
                  className={styles.next_btn}
                  disabled={!isCopied}
                  onClick={() => handleStep(2)}
                >
                  Next
                </button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div>
                <h2 className={styles.message}>
                  Sent the email?
                  <br /> Check out your email in dark mode
                </h2>
              </div>
              <div className={styles.final_btn_container}>
                <button
                  className={styles.previous_button}
                  onClick={waitForGmail}
                >
                  Preview dark mode
                </button>
                <p className={styles.back_link} onClick={() => handleStep(1)}>
                  Go back
                </p>
              </div>
            </>
          )}
          <div className={styles.mobile_padding} style={{ height: "140px" }} />
        </div>
      )}

      {/* <Stack mt={10} height="100vh">
        {isLoading ? (
          <LoadingPage />
        ) : (
          <Stack width="100%">
            {step === 1 && (
              <Stack width="100%" alignItems="center">
                <Snippet
                  text="Send your email template to the address below"
                  step="Step 1"
                />
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ height: "50px", width: "50%" }}
                  my={5}
                >
                  <OutlinedInput
                    fullWidth
                    variant="outlined"
                    value={emailId.email}
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    }
                    disableFocusRipple
                    sx={{
                      height: "100%",
                      "& .MuiInputBase-root": {
                        height: "100%",
                      },
                      "& fieldset": {
                        borderColor: "#5858E0",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRightWidth: 0,
                      },
                      "& input": {
                        height: "100%",
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    onClick={copyInputText}
                    disableElevation
                    sx={{
                      width: "30%",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      fontSize: "18px",
                      textTransform: "unset",
                      borderWidth: "2px",
                      borderColor: "#5858E0",
                      "&:hover": {
                        backgroundColor: isCopied
                          ? "rgba(88, 88, 224, 1)"
                          : "rgba(255, 255, 255)",
                        borderWidth: "2px",
                        borderLeftWidth: "1px",
                      },
                      backgroundColor: isCopied
                        ? "rgba(88, 88, 224, 1)"
                        : "rgba(88, 88, 224, 0.06)",

                      color: isCopied
                        ? "rgba(255, 255, 255)"
                        : "rgba(88, 88, 224, 1)",
                    }}
                  >
                    {isCopied ? "Copied" : "Copy"}
                  </Button>
                </Stack>
                <Typography
                  variant="h6"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color="rgba(88, 88, 224, 1)"
                  mt={6}
                  onClick={() => handleStep(2)}
                >
                  Next
                </Typography>
              </Stack>
            )}
            {step === 2 && (
              <Stack alignItems="center">
                <Stack alignItems="center">
                  <Snippet text="Sent the email? Confirm below" step="Step 2" />
                  <Button
                    variant="contained"
                    sx={{ p: 2, my: 5, textTransform: "initial" }}
                    onClick={waitForGmail}
                  >
                    Email Has Been Sent
                  </Button>
                </Stack>
                <Typography
                  variant="h6"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color="rgba(88, 88, 224, 1)"
                  mt={6}
                  onClick={() => handleStep(1)}
                >
                  Go Back
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Stack> */}
    </>
  );
};

export default Email;
