import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import LINK_ICON from "../../../../assets/link.svg";
import WARNING_ICON from "../../../../assets/warning_circle.svg";
import CHECK_ICON from "../../../../assets/check_light.svg";
import { validateEmail } from "../../../../global/ValidateEmail";
import axios from "axios";

function SharePreviewModal({ isOpen, handleClose, alias }) {
  const [email, setEmail] = useState({ name: "", error: "" });
  const [name, setName] = useState({ id: "", error: "" });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isShowValidEmailIcon, setShowValidEmailIcon] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState("");

  const copiedFromInputField = () => {
    setIsCopied(true);
  };

  const copyInputText = () => {
    navigator.clipboard.writeText(`https://www.checkdarkmode.com/${alias}`);
    setIsCopied(true);
  };

  const handleApiCall = () => {
    if (!validateEmail(email.id)) {
      setEmail({ ...email, error: true });
      if (!name.name) {
        setName({ ...name, error: true });
        return;
      }
      return;
    }
    if (!name.name) {
      setName({ ...name, error: true });
      return;
    }
    setIsLoading(true);
    const API =
      "https://api.mailmodo.com/api/v1/at/c/RUFAALxuXm/5f87bbca-30dd-59b3-9536-aab578528253";
    axios
      .post(API, {
        email: email.id,
        data: {
          name: name.name,
          "darkmode-share-url": `https://www.checkdarkmode.com/${alias}`,
        },
      })
      .then(() => {
        setIsEmailSent(true);
        setIsApiError("");
      })
      .catch((err) => {
        console.error(err.response);
        setIsApiError("Something went wrong. Please retry");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleEmailInput = ({ target }) => {
    setIsEmailSent(false);
    if (validateEmail(email.id)) {
      setEmail({ error: false, id: target.value });
      setShowValidEmailIcon(true);
    } else {
      setEmail({ ...email, id: target.value });
      setShowValidEmailIcon(false);
    }
  };
  const handleNameInput = ({ target }) => {
    setIsEmailSent(false);
    setName({ error: false, name: target.value });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ maxWidth: "510px", margin: "auto" }}
      fullWidth
      PaperProps={{ sx: { margin: "18px", width: "100%" } }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h3>Share preview</h3>
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>Send it to your team via email</p>
          <div className={styles.form}>
            <div className={styles.input_container}>
              <input
                type="text"
                placeholder="Your name"
                onChange={handleNameInput}
                value={name.name}
              />
              {name.error && (
                <img
                  src={WARNING_ICON}
                  className={styles.before_icon}
                  height="20"
                  width="20"
                  alt="envelope"
                  title={"Required"}
                />
              )}
            </div>
            <div className={styles.input_container}>
              <input
                type="email"
                placeholder="Email ID"
                onChange={handleEmailInput}
                value={email.id}
              />
              {email.error && (
                <img
                  src={WARNING_ICON}
                  className={styles.before_icon}
                  height="20"
                  width="20"
                  alt="envelope"
                  title={email.id ? "Invalid Email" : "Enter Email"}
                />
              )}
              {isShowValidEmailIcon && (
                <img
                  src={CHECK_ICON}
                  className={`${styles.before_icon} ${styles.dark}`}
                  height="20"
                  width="20"
                  alt="envelope"
                />
              )}
            </div>
            <button onClick={handleApiCall} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress color="inherit" size="25px" />
              ) : (
                "Send"
              )}
            </button>
          </div>
          {isEmailSent && (
            <p className={styles.success_message}>
              <span>
                <CheckIcon />
              </span>
              The preview has been sent via email
            </p>
          )}
          {isApiError && <p className={styles.warning}>{isApiError}</p>}
        </div>
        <div className={styles.footer}>
          <p className={styles.title}>Share preview link</p>
          <div className={styles.form}>
            <div className={styles.input_container}>
              <input
                type="text"
                id="email-input"
                value={`https://www.checkdarkmode.com/${alias}`}
                onCopy={copiedFromInputField}
                onChange={() => {}}
              />
              <img
                src={LINK_ICON}
                className={styles.before_icon}
                height="20"
                width="20"
                alt="envelope"
              />
            </div>
            <button data-is-copied={isCopied} onClick={copyInputText}>
              {isCopied ? "Copied" : "Copy link"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SharePreviewModal;
