import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
// import Image from "next/image";
// import Link from "next/link";

const Hero = () => {
  return (
    <div className={styles.image_container}>
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="36px"
        height="35px"
        className={styles.diamond_large_left}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="20px"
        height="20px"
        className={styles.diamond_medium_left}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="16px"
        height="15px"
        className={styles.diamond_small_left}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="36px"
        height="35px"
        className={styles.diamond_large_right}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="20px"
        height="20px"
        className={styles.diamond_medium_right}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/v1678813189/strapi/dimond_white_690cb54fd4.png"
        alt="diamond"
        width="16px"
        height="15px"
        className={styles.diamond_small_right}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/v1678814733/strapi/leftgradient_3bd2976ed3.png"
        alt="gradient"
        width="473px"
        height="1131px"
        className={styles.gradient_left_desktop}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/v1678814735/strapi/bottomrightgradient_4d01a371f5.png"
        alt="gradient"
        width="1440px"
        height="984px"
        className={styles.gradient_bottom_desktop}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/v1678816140/strapi/Group_1110165291_8c6ad89310.png"
        alt="gradient"
        width="238px"
        height="720px"
        className={styles.gradient_top_left_mobile}
      />
      <img
        src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/v1678816140/strapi/Group_1110165290_0f12fcaada.png"
        alt="gradient"
        width="360px"
        height="720px"
        className={styles.gradient_bottom_right_mobile}
      />
      <div className={styles.text_image_container1}>
        <h3 className={styles.header1}>
          Say goodbye to
          <br />
          dark mode email surprises
        </h3>
        <p className={styles.para1}>
          Check dark-mode compatibility before hitting send. See how it looks in
          Gmail, Outlook, Apple Mail & others.
        </p>
      </div>
      <div className={styles.div_container}>
        <div className={styles.btndiv}>
          <Link to="/dark-mode-simulator/">
            <button className={styles.btn}>Preview in dark mode</button>
          </Link>
        </div>
      </div>
      <hr className={styles.line_break} />
      <div className={styles.container_mobile}>
        <div className={styles.second_container}>
          <div className={styles.mobile}>
            <div className={styles.group1}>
              <p className={styles.para3}>How it works</p>
              <div className={styles.borderLine}>
                <img
                  src="https://res.cloudinary.com/mailmodo/image/upload/v1674682838/strapi/Line_f938a111a5.svg"
                  width={167}
                  height={1}
                  alt="line"
                />
              </div>
            </div>
          </div>
          <div className={styles.desktop}>
            <p className={styles.para2}>How it works</p>
          </div>
          <div className={styles.list1}>
            <div className={styles.list_1}>
              <div className={styles.list_1_1}>
                <p className={styles.para1_1}>01</p>
              </div>
              <div className={styles.text1}>
                <p className={styles.text_1}> Send your email template</p>
              </div>
            </div>
            <div className={styles.list_1}>
              <div className={styles.list_1_1}>
                <p className={styles.para1_1}>02</p>
              </div>
              <div className={styles.text1}>
                <p className={styles.text_1}> Preview in dark mode</p>
              </div>
            </div>
            <div className={styles.list_1}>
              <div className={styles.list_1_1}>
                <p className={styles.para1_1}>03</p>
              </div>
              <div className={styles.text1}>
                <p className={styles.text_1}> Share it with others</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.desktop}>
        <div className={styles.image_1}>
          <img
            src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/Group_1110165323_35f0ae5928.png"
            width={896}
            height={908}
            alt="Year in review"
          />
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.image_2}>
          <img
            src="https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_50/strapi/year_In_Review_Mobile_Card_d421d10961.png"
            width={360}
            height={432}
            alt={"Year in review"}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
