import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import styles from "./styles.module.scss";
import WARNING_ICON from "../../../../assets/warning_circle.svg";
import CHECK_ICON from "../../../../assets/check_light.svg";
import { validateEmail } from "../../../../global/ValidateEmail";
import NOTIFICATION_IMG from "../../../../assets/notification.svg";

function GetPlaybookModal({ isOpen, handleClose }) {
  const [email, setEmail] = useState({ id: "", error: "" });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isShowValidEmailIcon, setShowValidEmailIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiError, setIsApiError] = useState("");
  const handleApiCall = () => {
    if (!validateEmail(email.id)) {
      setEmail({ ...email, error: true });
      return;
    }
    setIsLoading(true);
    const API =
      "https://eof7oofr4joja4p.m.pipedream.net?url=https://api.mailmodo.com/hooks/start/f09549e3-f511-4041-832e-5b05594e80a4";
    axios
      .post(API, {
        email: email.id,
        data: { lead_magnet: "Dark Mode Checker" }, // Adding lead_magnet in data will send the data to  Hubspot as well
      })
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((err) => {
        console.error(err.response);
        setIsApiError("Something went wrong. Please retry");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleEmailInput = ({ target }) => {
    setIsApiError("");
    if (validateEmail(email.id)) {
      setEmail({ error: false, id: target.value });
      setShowValidEmailIcon(true);
    } else {
      setEmail({ ...email, id: target.value });
      setShowValidEmailIcon(false);
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ maxWidth: `${isEmailSent ? "541px" : "632px"}`, margin: "auto" }}
      fullWidth
      PaperProps={{ sx: { margin: "18px", width: "100%" } }}
    >
      {isEmailSent ? (
        <div className={styles.success_container}>
          <span onClick={handleClose}>
            <CloseIcon />
          </span>
          <img
            src={NOTIFICATION_IMG}
            alt="notification"
            loading="lazy"
            width="59px"
            height="49px"
          />
          <h3>Check your inbox</h3>
          <p>
            We’ve delivered the Dark Mode
            <br /> playbook to you
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <h3>Get the Dark Mode Email Playbook in your inbox</h3>
            <span onClick={handleClose}>
              <CloseIcon />
            </span>
          </div>
          <div className={styles.body}>
            <div className={styles.form}>
              <div className={styles.input_container}>
                <input
                  type="email"
                  placeholder="Enter your email ID"
                  onChange={handleEmailInput}
                  value={email.id}
                />
                {email.error && (
                  <img
                    src={WARNING_ICON}
                    className={styles.before_icon}
                    height="20"
                    width="20"
                    alt="envelope"
                    title={email.id ? "Invalid Email" : "Enter Email"}
                  />
                )}
                {isShowValidEmailIcon && (
                  <img
                    src={CHECK_ICON}
                    className={`${styles.before_icon} ${styles.dark}`}
                    height="20"
                    width="20"
                    alt="envelope"
                  />
                )}
              </div>
              <button data-is-sent={isEmailSent} onClick={handleApiCall}>
                {isLoading ? (
                  <CircularProgress color="inherit" size="25px" />
                ) : (
                  "Get now"
                )}
              </button>
            </div>
            {isApiError && <p className={styles.warning}>{isApiError}</p>}
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default GetPlaybookModal;
