import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmailBody } from "../../api";
import CLOSE_ICON from "../../assets/close.svg";
import HTML_ICON from "../../assets/html_button.svg";
import AMP_ICON from "../../assets/amp_button.svg";
import HTML_ICON_LIGHT from "../../assets/html_button_light.svg";
import AMP_ICON_LIGHT from "../../assets/amp_button_light.svg";
import REPLAY_ICON from "../../assets/ArrowCounterClockwise.svg";
import USER_GROUP_ICON from "../../assets/user_group_add_outlined.svg";
import CHEVRON_MOBILE from "../../assets/chevron_mobile.svg";
import { Alert } from "../../global/Alert";
import { Preview } from "./Preview/Preview";
import styles from "./styles.module.scss";
import AmpNotAvailable from "./AmpNotAvailable/AmpNotAvailable";
import SharePreviewModal from "./Modals/SharePreviewModal/SharePreviewModal";
import GetPlaybookModal from "./Modals/GetPlaybookModal/GetPlaybookModal";

const DarkModeSimulator = () => {
  // const classes = useClasses(styles);
  const [value, setValue] = useState(0);
  const [isNoteVisible, setIsNoteVisible] = useState(true);
  const [isEmailTypeVisible, setIsEmailTypeVisible] = useState(false);
  const [htmlBody, setHtmlBody] = useState({ html: "", amp: "" });
  const [isAMP, setIsAMP] = useState(false);
  const [selectedModeMobile, setSelectedModeMobile] = useState({});
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    type: "share",
  });
  const [alias, setAlias] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const apiHandler = async () => {
    const response = await getEmailBody(location.pathname.substring(1));
    setAlias(location.pathname.substring(1));
    if (response.status) {
      const body = {
        html: response.htmlBody,
        amp: response?.ampBody ? response.ampBody : "",
      };
      setHtmlBody(body);
      setValue(1);
    } else {
      Alert.showError("Something wrong while fetching email body");
    }
  };

  useEffect(() => {
    apiHandler();
    // eslint-disable-next-line
  }, []);

  const handleOpenModal = (value) => {
    if (value === "share")
      setIsModalOpen({ ...isModalOpen, isOpen: true, type: "share" });
    else setIsModalOpen({ ...isModalOpen, isOpen: true, type: "email" });
  };
  const handleModalClose = () => {
    setIsModalOpen({ ...isModalOpen, isOpen: !isModalOpen.isOpen });
  };

  const handleAMP = (val) => {
    if (val !== isAMP) {
      setIsAMP(val);
      setValue(0);
    }
  };
  const goBack = () => {
    navigate("/dark-mode-simulator");
  };

  const handleModeChange = (val) => {
    // if email type APM is selected and APM is not available then set original mode as default
    if (val !== value) {
      if (!htmlBody.amp && isAMP) {
        setValue(0);
        return;
      }
      const obj = ButtonsDataMobile.find((item) => item.id === val);
      setSelectedModeMobile(obj);
      setValue(val);
    }
  };

  // const checkIfAMP = (val) => {
  //   if (val && isAMP && !htmlBody.amp) {
  //     if (value !== 0) {
  //       setValue(0);
  //     }
  //     return true;
  //   }

  //   if (!val && htmlBody.amp && isAMP) {
  //     if (value !== 0 && val) {
  //       setValue((val) => val);
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  const ButtonsData = [
    {
      id: 0,
      title: "Original",
    },
    {
      id: 1,
      title: "Partial Inversion",
      subtitle1:
        "It only detects areas with light backgrounds, and then inverts them so the light backgrounds turn into dark backgrounds. Anything already on a dark background is left as is.",
      subtitle2: "Clients: Outlook (iOS, macOS, Webmail), Gmail (Android)",
    },
    {
      id: 2,
      title: "Full Inversion",
      subtitle1:
        "It inverts all light colors to dark and all dark colors become light. Images are converted and they stay the same.",
      subtitle2: "Clients: Apple Mail, Outlook (Windows), Gmail (iOS)",
    },
  ];
  const ButtonsDataMobile = [
    {
      id: 1,
      title: "Partial",
      subtitle1:
        "It only detects areas with light backgrounds, and then inverts them so the light backgrounds turn into dark backgrounds. Anything already on a dark background is left as is.",
      subtitle2: "Clients: Outlook (iOS, macOS, Webmail), Gmail (Android)",
    },
    {
      id: 2,
      title: "Full",
      subtitle1:
        "It inverts all light colors to dark and all dark colors become light. Images are converted and they stay the same.",
      subtitle2: "Clients: Apple Mail, Outlook (Windows), Gmail (iOS)",
    },
    {
      id: 0,
      title: "None",
    },
  ];

  return (
    <>
      {isModalOpen.isOpen && isModalOpen.type === "share" && (
        <SharePreviewModal
          isOpen={isModalOpen.isOpen}
          handleClose={handleModalClose}
          alias={alias}
        />
      )}
      {isModalOpen.isOpen && isModalOpen.type === "email" && (
        <GetPlaybookModal
          isOpen={isModalOpen.isOpen}
          handleClose={handleModalClose}
        />
      )}

      <div className={styles.container}>
        <div className={styles.row}>
          <div
            className={`${styles.col_left}`}
            data-is-visible={isEmailTypeVisible}
          >
            <div>
              <div className={styles.header}>
                <h2 className={styles.title}>Preview</h2>
                <div className={styles.reset_link} onClick={goBack}>
                  <img src={REPLAY_ICON} width="20" height="20" alt="reset" />
                  Test another email
                </div>
                <div
                  className={styles.show_more}
                  onClick={() => {
                    setIsEmailTypeVisible((oldState) => !oldState);
                  }}
                >
                  More
                  <img
                    src={CHEVRON_MOBILE}
                    width="15"
                    height="6"
                    alt="CHEVRON"
                    data-is-active={isEmailTypeVisible}
                  />
                </div>
              </div>
              <div className={styles.modes_container}>
                <p className={styles.title}>Mode</p>
                {ButtonsData.map((card, index) => (
                  <div
                    className={styles.card}
                    key={index}
                    data-index={index}
                    data-is-active={value === index}
                    onClick={() => {
                      handleModeChange(index);
                    }}
                  >
                    <div className={styles.card_title}>
                      <span>{card.title}</span>
                      {card.subtitle1 && (
                        <span className={styles.charvon}>
                          <ExpandLessIcon />
                        </span>
                      )}
                    </div>
                    {card.subtitle1 && (
                      <div className={styles.card_body}>
                        <p className={styles.description}>{card.subtitle1}</p>
                        <p className={styles.note}>{card.subtitle2}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className={styles.modes_container_mobile}>
                <p className={styles.title}>Mode</p>
                <div className={styles.chips}>
                  {ButtonsDataMobile.map((item) => (
                    <div
                      className={styles.chip}
                      key={item.id}
                      data-is-active={value === item.id}
                      onClick={() => {
                        handleModeChange(item.id);
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
                {!!selectedModeMobile.subtitle1 &&
                  !!selectedModeMobile.subtitle2 && (
                    <>
                      <p className={styles.subtile1}>
                        {selectedModeMobile.subtitle1}
                      </p>
                      <p className={styles.subtile2}>
                        {selectedModeMobile.subtitle2}
                      </p>
                    </>
                  )}
              </div>
              <div
                className={styles.switch_container}
                data-is-visible={isEmailTypeVisible}
              >
                <h3 className={styles.title}>Email Type</h3>
                <div className={styles.buttons}>
                  <div
                    className={styles.button}
                    data-is-active={!isAMP}
                    onClick={() => handleAMP(false)}
                  >
                    <img
                      src={isAMP ? HTML_ICON : HTML_ICON_LIGHT}
                      width="24"
                      height="24"
                      alt="html"
                    />
                    <div>
                      <p className={styles.label}>HTML</p>
                      <p className={styles.sub_label}>Static emails</p>
                    </div>
                  </div>
                  <div
                    className={styles.button}
                    data-is-active={isAMP}
                    onClick={() => handleAMP(true)}
                  >
                    <img
                      src={isAMP ? AMP_ICON_LIGHT : AMP_ICON}
                      width="24"
                      height="24"
                      alt="html"
                    />
                    <div>
                      <p className={styles.label}>AMP</p>
                      <p className={styles.sub_label}>Interactive emails</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={styles.share_preview}
                data-is-visible={isEmailTypeVisible}
                onClick={() => handleOpenModal("share")}
              >
                <img src={USER_GROUP_ICON} alt="share" width="20" height="22" />
                <p>Share this preview with your team</p>
              </div>
              <div className={styles.dark_cta_container}>
                <img
                  src="https://res.cloudinary.com/mailmodo/image/upload/v1679382436/strapi/Group_1110165355_f3d24d3cb6.svg"
                  alt="dark mode"
                  width="94"
                  height="74"
                />
                <div>
                  <p className={styles.title}>
                    Get our <b>Dark Mode Email</b> Playbook
                  </p>
                  <p
                    className={styles.link}
                    onClick={() => handleOpenModal("email")}
                  >
                    <span>Get it now</span>
                    {" ->"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-is-dark={value === 1 || value === 2}
            data-is-note-visible={isNoteVisible}
            className={`${styles.col_right}`}
          >
            {/* always true if HTML type selected, in case AMP type selected then check for if amp exists ot not */}
            {!!htmlBody.amp || !isAMP ? (
              <>
                {isNoteVisible && value !== 0 && (
                  <div className={styles.note}>
                    <p>
                      <b>Note:</b>{" "}
                      <i>
                        This is an illustrative representation and may not
                        accurately represent the final result.
                      </i>
                    </p>
                    <img
                      src={CLOSE_ICON}
                      width="20"
                      height="20"
                      alt="close"
                      onClick={() => {
                        setIsNoteVisible(false);
                      }}
                    />
                  </div>
                )}
                {isAMP ? (
                  <Preview value={value} key="amp" codeData={htmlBody.amp} />
                ) : (
                  <Preview key="html" value={value} codeData={htmlBody.html} />
                )}
              </>
            ) : (
              <AmpNotAvailable />
            )}
          </div>
        </div>
        <div className={styles.dark_cta_mobile_container}>
          <img
            src="https://res.cloudinary.com/mailmodo/image/upload/v1679382436/strapi/Group_1110165355_f3d24d3cb6.svg"
            alt="dark mode"
            width="94"
            height="74"
          />
          <div>
            <p className={styles.title}>Get our Dark Mode Email Playbook</p>
            <p className={styles.link} onClick={() => handleOpenModal("email")}>
              <span>Get it now</span>
              {" ->"}
            </p>
          </div>
        </div>
        <div className={styles.reset_link_mobile} onClick={goBack}>
          <img src={REPLAY_ICON} width="20" height="20" alt="reset" />
          Test another email
        </div>
      </div>
    </>
  );
};

export default DarkModeSimulator;
