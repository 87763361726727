import React from "react";
import styles from "./styles.module.scss";
import AMP_GIF from "../../../assets/amp.gif";
import CHECK_ICON from "../../../assets/check.svg";

const AmpNotAvailable = () => {
  const handleURL = () => {
    window
      .open("https://mailmodo.com?utm_source=checkdarkmode", "_blank")
      .focus();
  };

  return (
    <div className={styles.amp_not_available_container}>
      <h2 className={styles.heading}>Still not using AMP emails? 😕</h2>
      <div className={styles.card}>
        <div className={styles.card_row}>
          <div className={styles.card_body_left}>
            <img
              src={AMP_GIF}
              alt="gif displaying amp usage"
              width="246"
              height="246"
              className={styles.gif}
            />
          </div>
          <div className={styles.card_body_right}>
            <h3 className={styles.title}>With AMP emails, you can</h3>
            <div className={styles.list}>
              <img src={CHECK_ICON} alt="check icon" width="20" height="20" />
              <p>
                Add forms, quiz, poll, calendar
                <br /> & more inside emails
              </p>
            </div>
            <div className={styles.list}>
              <img src={CHECK_ICON} alt="check icon" width="20" height="20" />
              <p>
                Let your users engage & take action without switching to a
                landing page
              </p>
            </div>
            <div className={styles.list}>
              <img src={CHECK_ICON} alt="check icon" width="20" height="20" />
              <p>
                Get higher engagement
                <br /> & conversions
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.footer}>
          <p className={styles.desc}>
            Create & send AMP emails
            <br /> easily with Mailmodo
          </p>
          <button className={styles.button} onClick={handleURL}>
            Try Mailmodo for FREE
          </button>
        </div>
      </div>
    </div>
  );
};

export default AmpNotAvailable;
