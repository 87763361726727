import React, { useEffect } from "react";
import Hero from "./Hero/Hero";
import Features from "./Features/Features";
import Awards from "./Awards/Awards";
import Footer from "../layouts/Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Hero />
      <Features />
      <Awards />
      <Footer />
    </>
  );
};

export default Home;
