export function getImageUrl(path = "", format = "webp", quality = 50) {
  //support for videos as some older videos use getImageUrl for src BEGIN
  if (path.includes(".mp4")) {
    format = "webm";
    if (path.startsWith("/")) {
      return `https://res.cloudinary.com/mailmodo/video/upload/f_${format},q_${quality}/v1628497684/strapi/${path}`;
    } else {
      //If video is uploaded on cloudinary then custom qualtiy, format adjustments can be made on demand.
      if (path.includes("https://res.cloudinary.com/")) {
        const pathArr = path.split("/");
        for (let x = 0; x < pathArr.length; x++) {
          //if cloudinary video already has quality, format etc params then do not change it, return it as it is.
          if (pathArr[x].includes("f_") || pathArr[x].includes("q_")) {
            return path;
          }
        }
        return pathArr
          .join("/")
          .replace("/upload/", `/upload/f_${format},q_${quality}/`); //if cloudinary video doesn't have qualtiy, format etc params, then add the params and return it.
      }
      return path; //video was not from cloudinary, return as it is.
    }
  }
  //support for videos as some videos use getImageUrl END

  //main logic for getImageUrl
  if (path.startsWith("/")) {
    //for older images
    return `https://res.cloudinary.com/mailmodo/image/upload/f_${format},q_${quality}/v1628497684/strapi/${path}`;
  } else {
    //If image is uploaded on cloudinary then custom qualtiy, format adjustments can be made on demand.
    if (path.includes("https://res.cloudinary.com/")) {
      const pathArr = path.split("/");
      for (let x = 0; x < pathArr.length; x++) {
        //if cloudinary image already has quality, format etc params then do not change it, return it as it is.
        if (pathArr[x].includes("f_") || pathArr[x].includes("q_")) {
          return path;
        }
      }
      return pathArr
        .join("/")
        .replace("/upload/", `/upload/f_${format},q_${quality}/`); //if cloudinary image doesn't have qualtiy, format etc params, then add the params and return it.
    }
    return path; //image was not from cloudinary, return as it is.
  }
}
