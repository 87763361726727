import React, { useRef, useEffect } from "react";

const Iframe = ({ content, id, processInversion }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const frame = iframeRef.current;
    if (!frame) {
      return;
    }
    let doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
    frame.style.width = "100%";
    frame.style.height = "calc(100vh - 180px)";
    if (processInversion) {
      processInversion();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <iframe
        id={id}
        src="about:blank"
        ref={iframeRef}
        title="darkMode"
        style={{ border: "none" }}
      />
    </>
  );
};

export default Iframe;

// import React from "react";
// const Iframe = ({ content, id, processInversion }) => {
//   const writeHTML = (frame) => {
//     if (!frame) {
//       return;
//     }
//     let doc = frame.contentDocument;
//     doc.open();
//     doc.write(content);
//     doc.close();
//     frame.style.width = "100%";
//     frame.style.height = "calc(100vh - 180px)";
//     if(processInversion){
//       processInversion()
//     }
//   };
//   return (
//     <>
//       <iframe
//         id={id}
//         src="about:blank"
//         ref={writeHTML}
//         title="darkMode"
//         style={{ border: "none" }}
//       />
//     </>
//   );
// };
// export default Iframe;
