import React from "react";
import styles from "./awards.module.scss";
import { Link } from "react-router-dom";

const Awards = () => {
  return (
    <>
      <div
        className={styles.cta_container}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className={styles.hbm2t4}>
          <div className={styles.logo_box}>
            <div className={styles.img_container}></div>
            <img
              src={
                "https://res.cloudinary.com/mailmodo/image/upload/v1674680971/strapi/Group_1110165300_eea33e45a2.svg"
              }
              alt={"Company logo"}
              width={442}
              height={47.55}
            />
          </div>
          <div className={styles.text1}>
            The email marketing platform to supercharge your email-led growth
          </div>
          <div className={styles.block1}>
            <div className={styles.checks}>
              <div className={styles.box_main}>
                <div style={{ minWidth: "44px", display: "flex" }}>
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                    }
                    alt={"check logo"}
                    width={44}
                    height={44}
                  />
                </div>
                <p className={styles.text2}>
                  Create 3x converting interactive emails
                </p>
              </div>
              <div className={styles.box_main}>
                <div style={{ minWidth: "44px", display: "flex" }}>
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                    }
                    alt={"check logo"}
                    width={44}
                    height={44}
                  />
                </div>

                <p className={styles.text2}>
                  Send emails skipping spam folders
                </p>
              </div>

              <div className={styles.box_main}>
                <div style={{ minWidth: "44px", display: "flex" }}>
                  <img
                    src={
                      "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                    }
                    alt={"check logo"}
                    width={44}
                    height={44}
                  />
                </div>

                <p className={styles.text2}>
                  Automate user journeys to save time
                </p>
              </div>
            </div>
          </div>
          <div className={styles.div_container}>
            <div className={styles.btndiv}>
              <Link to="https://manage.mailmodo.com/auth/signup">
                <button className={styles.btn}> Try FREE for 21 Days </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.mobile} ${styles.mobile_container}`}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className={styles.awardsMobile}>
          <div className={styles.logoMobile}>
            <img
              src={
                "https://res.cloudinary.com/mailmodo/image/upload/f_webp,q_100/strapi/mobile_Mode_Logo_e4e7d894f7.png"
              }
              alt={"Mobile view logo Mailmodo"}
              width={148}
              height={48}
            />
          </div>
          <p className={styles.mobilePara}>
            The email marketing platform to
            <br /> supercharge your email-led growth
          </p>
          <div className={styles.list_mobile}>
            <div className={styles.itemline1}>
              <div className={styles.division}>
                <img
                  src={
                    "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                  }
                  alt={"check logo"}
                  width={26}
                  height={26}
                />

                <p className={styles.text3}>
                  Create 3x converting interactive emails
                </p>
              </div>
              <div className={styles.division}>
                <img
                  src={
                    "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                  }
                  alt={"check logo"}
                  width={26}
                  height={26}
                />

                <p className={styles.text3}>
                  Send emails skipping spam folders
                </p>
              </div>
            </div>
            <div className={styles.division}>
              <img
                src={
                  "https://res.cloudinary.com/mailmodo/image/upload/v1672650131/strapi/check_cefc3c2dd5.png"
                }
                alt={"check logo"}
                width={26}
                height={26}
              />
              <p className={styles.text3}>
                Automate user journeys to save time
              </p>
            </div>
          </div>
          <div className={styles.btndivMobile}>
            <Link to="https://manage.mailmodo.com/auth/signup">
              <button className={styles.btnMobile}>Try FREE for 21 Days</button>
            </Link>
          </div>
          <div>
            <img
              src={
                "https://res.cloudinary.com/mailmodo/image/upload/v1674532593/strapi/footer_Dark_Mode_Mobile_13dffc289c.png"
              }
              width={"100%"}
              height={246}
              loading="lazy"
              style={{
                objectFit: "cover",
                display: "flex",
                marginBottom: "-2px",
              }}
              alt="templates"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
